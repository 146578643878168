.modal {
    transition: opacity .25s ease;
}

:root {
    --balloon-border-radius: 2px;
    --balloon-color: rgba(16, 16, 16, 0.95);
    --balloon-text-color: #fff;
    --balloon-font-size: 12px;
    --balloon-move: 4px;
}

button[aria-label][data-balloon-pos] {
    overflow: visible;
}

[aria-label][data-balloon-pos] {
    position: relative;
    cursor: pointer;
}

[aria-label][data-balloon-pos]:after {
    text-indent: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: none;
    font-size: var(--balloon-font-size);
    background: var(--balloon-color);
    border-radius: 2px;
    color: var(--balloon-text-color);
    border-radius: var(--balloon-border-radius);
    content: attr(aria-label);
    padding: .5em 1em;
    white-space: nowrap;
}

[aria-label][data-balloon-pos]:after,
[aria-label][data-balloon-pos]:before {
    opacity: 0;
    pointer-events: none;
    transition: all .18s ease-out .18s;
    position: absolute;
    z-index: 10;
}

[aria-label][data-balloon-pos]:before {
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: var(--balloon-color);
    content: "";
}

[aria-label][data-balloon-pos]:hover:after,
[aria-label][data-balloon-pos]:hover:before,
[aria-label][data-balloon-pos]:not([data-balloon-nofocus]):focus:after,
[aria-label][data-balloon-pos]:not([data-balloon-nofocus]):focus:before,
[aria-label][data-balloon-pos][data-balloon-visible]:after,
[aria-label][data-balloon-pos][data-balloon-visible]:before {
    opacity: 1;
    pointer-events: none;
}

[aria-label][data-balloon-pos].font-awesome:after {
    font-family: FontAwesome, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

[aria-label][data-balloon-pos][data-balloon-break]:after {
    white-space: pre;
}

[aria-label][data-balloon-pos][data-balloon-break][data-balloon-length]:after {
    white-space: pre-line;
    word-break: break-word;
}

[aria-label][data-balloon-pos][data-balloon-blunt]:after,
[aria-label][data-balloon-pos][data-balloon-blunt]:before {
    transition: none;
}

[aria-label][data-balloon-pos][data-balloon-pos=down]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos=down]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos=down][data-balloon-visible]:after,
[aria-label][data-balloon-pos][data-balloon-pos=down][data-balloon-visible]:before,
[aria-label][data-balloon-pos][data-balloon-pos=up]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos=up]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos=up][data-balloon-visible]:after,
[aria-label][data-balloon-pos][data-balloon-pos=up][data-balloon-visible]:before {
    transform: translate(-50%);
}

[aria-label][data-balloon-pos][data-balloon-pos*=-left]:after {
    left: 0;
}

[aria-label][data-balloon-pos][data-balloon-pos*=-left]:before {
    left: 5px;
}

[aria-label][data-balloon-pos][data-balloon-pos*=-right]:after {
    right: 0;
}

[aria-label][data-balloon-pos][data-balloon-pos*=-right]:before {
    right: 5px;
}

[aria-label][data-balloon-pos][data-balloon-po*=-left]:hover:after,
[aria-label][data-balloon-pos][data-balloon-po*=-left]:hover:before,
[aria-label][data-balloon-pos][data-balloon-po*=-left][data-balloon-visible]:after,
[aria-label][data-balloon-pos][data-balloon-po*=-left][data-balloon-visible]:before,
[aria-label][data-balloon-pos][data-balloon-pos*=-right]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos*=-right]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos*=-right][data-balloon-visible]:after,
[aria-label][data-balloon-pos][data-balloon-pos*=-right][data-balloon-visible]:before {
    transform: translate(0);
}

[aria-label][data-balloon-pos][data-balloon-pos^=up]:after,
[aria-label][data-balloon-pos][data-balloon-pos^=up]:before {
    bottom: 100%;
    transform-origin: top;
    transform: translateY(var(--balloon-move));
}

[aria-label][data-balloon-pos][data-balloon-pos^=up]:after {
    margin-bottom: 10px;
}

[aria-label][data-balloon-pos][data-balloon-pos=up]:after,
[aria-label][data-balloon-pos][data-balloon-pos=up]:before {
    left: 50%;
    transform: translate(-50%, var(--balloon-move));
}

[aria-label][data-balloon-pos][data-balloon-pos^=down]:after,
[aria-label][data-balloon-pos][data-balloon-pos^=down]:before {
    top: 100%;
    transform: translateY(calc(var(--balloon-move)*-1));
}

[aria-label][data-balloon-pos][data-balloon-pos^=down]:after {
    margin-top: 10px;
}

[aria-label][data-balloon-pos][data-balloon-pos^=down]:before {
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-bottom-color: var(--balloon-color);
}

[aria-label][data-balloon-pos][data-balloon-pos=down]:after,
[aria-label][data-balloon-pos][data-balloon-pos=down]:before {
    left: 50%;
    transform: translate(-50%, calc(var(--balloon-move)*-1));
}

[aria-label][data-balloon-pos][data-balloon-pos=left]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos=left]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos=left][data-balloon-visible]:after,
[aria-label][data-balloon-pos][data-balloon-pos=left][data-balloon-visible]:before,
[aria-label][data-balloon-pos][data-balloon-pos=right]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos=right]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos=right][data-balloon-visible]:after,
[aria-label][data-balloon-pos][data-balloon-pos=right][data-balloon-visible]:before {
    transform: translateY(-50%);
}

[aria-label][data-balloon-pos][data-balloon-pos=left]:after,
[aria-label][data-balloon-pos][data-balloon-pos=left]:before {
    right: 100%;
    top: 50%;
    transform: translate(var(--balloon-move), -50%);
}

[aria-label][data-balloon-pos][data-balloon-pos=left]:after {
    margin-right: 10px;
}

[aria-label][data-balloon-pos][data-balloon-pos=left]:before {
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-left-color: var(--balloon-color);
}

[aria-label][data-balloon-pos][data-balloon-pos=right]:after,
[aria-label][data-balloon-pos][data-balloon-pos=right]:before {
    left: 100%;
    top: 50%;
    transform: translate(calc(var(--balloon-move)*-1), -50%);
}

[aria-label][data-balloon-pos][data-balloon-pos=right]:after {
    margin-left: 10px;
}

[aria-label][data-balloon-pos][data-balloon-pos=right]:before {
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-right-color: var(--balloon-color);
}

[aria-label][data-balloon-pos][data-balloon-length]:after {
    white-space: normal;
}

[aria-label][data-balloon-pos][data-balloon-length=small]:after {
    width: 80px;
}

[aria-label][data-balloon-pos][data-balloon-length=medium]:after {
    width: 150px;
}

[aria-label][data-balloon-pos][data-balloon-length=large]:after {
    width: 260px;
}

[aria-label][data-balloon-pos][data-balloon-length=xlarge]:after {
    width: 380px;
}

@media screen and (max-width:768px) {
    [aria-label][data-balloon-pos][data-balloon-length=xlarge]:after {
        width: 90vw;
    }
}

[aria-label][data-balloon-pos][data-balloon-length=fit]:after {
    width: 100%;
}

/* ********************************************************************************************************************************************************************************************************************************************************************************************** */
[data-simplebar] {
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
}

.simplebar-wrapper {
    overflow: hidden;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
}

.simplebar-mask {
    direction: inherit;
    overflow: hidden;
    width: auto !important;
    height: auto !important;
    z-index: 0;
}

.simplebar-mask,
.simplebar-offset {
    position: absolute;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.simplebar-offset {
    direction: inherit !important;
    box-sizing: inherit !important;
    resize: none !important;
    -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
    direction: inherit;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    height: 100%;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.simplebar-content:after,
.simplebar-content:before {
    content: " ";
    display: table;
}

.simplebar-placeholder {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
    box-sizing: inherit !important;
    height: 100%;
    width: 100%;
    max-width: 1px;
    position: relative;
    float: left;
    max-height: 1px;
    overflow: hidden;
    z-index: -1;
    padding: 0;
    margin: 0;
    pointer-events: none;
    flex-grow: inherit;
    flex-shrink: 0;
    flex-basis: 0;
}

.simplebar-height-auto-observer {
    box-sizing: inherit;
    display: block;
    opacity: 0;
    top: 0;
    left: 0;
    height: 1000%;
    width: 1000%;
    min-height: 1px;
    min-width: 1px;
    z-index: -1;
}

.simplebar-height-auto-observer,
.simplebar-track {
    position: absolute;
    overflow: hidden;
    pointer-events: none;
}

.simplebar-track {
    z-index: 1;
    right: 0;
    bottom: 0;
}

[data-simplebar].simplebar-dragging .simplebar-content {
    pointer-events: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
    pointer-events: all;
}

.simplebar-scrollbar {
    position: absolute;
    left: 0;
    right: 0;
    min-height: 10px;
}

.simplebar-scrollbar:before {
    position: absolute;
    content: "";
    background: #000;
    border-radius: 7px;
    left: 2px;
    right: 2px;
    opacity: 0;
    transition: opacity .2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
    opacity: .5;
    transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
    top: 0;
    width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    top: 2px;
    bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
    left: 0;
    height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
    height: 100%;
    left: 2px;
    right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    right: auto;
    left: 0;
    top: 2px;
    height: 7px;
    min-height: 0;
    min-width: 10px;
    width: auto;
}

[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
    right: auto;
    left: 0;
}

.hs-dummy-scrollbar-size {
    direction: rtl;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    height: 500px;
    width: 500px;
    overflow-y: hidden;
    overflow-x: scroll;
}

.simplebar-hide-scrollbar {
    position: fixed;
    left: 0;
    visibility: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

/* ********************************************************************************************************************************************************************************************************************************************************************************************** */
#top-notification a {
    text-decoration: underline;
}

/* ********************************************************************************************************************************************************************************************************************************************************************************************** */
#eQ9oJCygm525_tr {
    -webkit-animation: eQ9oJCygm525_tr__tr 1s linear infinite normal forwards;
    animation: eQ9oJCygm525_tr__tr 1s linear infinite normal forwards;
}

@-webkit-keyframes eQ9oJCygm525_tr__tr {
    0% {
        transform: translate(29.799999px, 20px) rotate(0deg);
    }

    to {
        transform: translate(29.799999px, 20px) rotate(1turn);
    }
}

@keyframes eQ9oJCygm525_tr__tr {
    0% {
        transform: translate(29.799999px, 20px) rotate(0deg);
    }

    to {
        transform: translate(29.799999px, 20px) rotate(1turn);
    }
}

/* ********************************************************************************************************************************************************************************************************************************************************************************************** */
.vue-slider-dot {
    position: absolute;
    -webkit-transition: all 0s;
    transition: all 0s;
    z-index: 5;
}

.vue-slider-dot:focus {
    outline: none;
}

.vue-slider-dot-tooltip {
    position: absolute;
    visibility: hidden;
}

.vue-slider-dot-hover:hover .vue-slider-dot-tooltip,
.vue-slider-dot-tooltip-show {
    visibility: visible;
}

.vue-slider-dot-tooltip-top {
    top: -10px;
    left: 50%;
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
}

.vue-slider-dot-tooltip-bottom {
    bottom: -10px;
    left: 50%;
    -webkit-transform: translate(-50%, 100%);
    transform: translate(-50%, 100%);
}

.vue-slider-dot-tooltip-left {
    left: -10px;
    top: 50%;
    -webkit-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
}

.vue-slider-dot-tooltip-right {
    right: -10px;
    top: 50%;
    -webkit-transform: translate(100%, -50%);
    transform: translate(100%, -50%);
}

/* ********************************************************************************************************************************************************************************************************************************************************************************************** */
.vue-slider-marks {
    position: relative;
    width: 100%;
    height: 100%;
}

.vue-slider-mark {
    position: absolute;
    z-index: 1;
}

.vue-slider-ltr .vue-slider-mark,
.vue-slider-rtl .vue-slider-mark {
    width: 0;
    height: 100%;
    top: 50%;
}

.vue-slider-ltr .vue-slider-mark-step,
.vue-slider-rtl .vue-slider-mark-step {
    top: 0;
}

.vue-slider-ltr .vue-slider-mark-label,
.vue-slider-rtl .vue-slider-mark-label {
    top: 100%;
    margin-top: 10px;
}

.vue-slider-ltr .vue-slider-mark {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.vue-slider-ltr .vue-slider-mark-step {
    left: 0;
}

.vue-slider-ltr .vue-slider-mark-label {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.vue-slider-rtl .vue-slider-mark {
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
}

.vue-slider-rtl .vue-slider-mark-step {
    right: 0;
}

.vue-slider-rtl .vue-slider-mark-label {
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
}

.vue-slider-btt .vue-slider-mark,
.vue-slider-ttb .vue-slider-mark {
    width: 100%;
    height: 0;
    left: 50%;
}

.vue-slider-btt .vue-slider-mark-step,
.vue-slider-ttb .vue-slider-mark-step {
    left: 0;
}

.vue-slider-btt .vue-slider-mark-label,
.vue-slider-ttb .vue-slider-mark-label {
    left: 100%;
    margin-left: 10px;
}

.vue-slider-btt .vue-slider-mark {
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
}

.vue-slider-btt .vue-slider-mark-step {
    top: 0;
}

.vue-slider-btt .vue-slider-mark-label {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.vue-slider-ttb .vue-slider-mark {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.vue-slider-ttb .vue-slider-mark-step {
    bottom: 0;
}

.vue-slider-ttb .vue-slider-mark-label {
    bottom: 50%;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
}

.vue-slider-mark-label,
.vue-slider-mark-step {
    position: absolute;
}

/* ********************************************************************************************************************************************************************************************************************************************************************************************** */
.vue-slider {
    position: relative;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.vue-slider-rail {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-transition-property: width, height, left, right, top, bottom;
    transition-property: width, height, left, right, top, bottom;
}

.vue-slider-process {
    position: absolute;
    z-index: 1;
}

/* ********************************************************************************************************************************************************************************************************************************************************************************************** */
.vue-slider-disabled .vue-slider-process {
    background-color: #a7a7a7;
}

.vue-slider-disabled .vue-slider-dot-handle {
    border-color: #a7a7a7;
}

.vue-slider-disabled .vue-slider-mark-step-active {
    box-shadow: 0 0 0 2px #a7a7a7;
}

.vue-slider-rail {
    background-color: #f5f5f5;
    border-radius: 15px;
    transition: background-color .3s;
}

.vue-slider:hover .vue-slider-rail {
    background-color: #e1e1e1;
}

.vue-slider-process {
    background-color: #9cd5ff;
    border-radius: 15px;
    transition: background-color .3s;
}

.vue-slider:hover .vue-slider-process {
    background-color: #69c0ff;
}

.vue-slider-mark-step {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #e8e8e8;
    background-color: #fff;
}

.vue-slider-mark-step-active {
    box-shadow: 0 0 0 2px #9cd5ff;
}

.vue-slider:hover .vue-slider-mark-step-active {
    box-shadow: 0 0 0 2px #69c0ff;
}

.vue-slider-mark-label {
    font-size: 12px;
    white-space: nowrap;
}

.vue-slider-dot-handle {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #9cd5ff;
    box-sizing: border-box;
    transition: box-shadow .3s, border-color .3s;
}

.vue-slider:hover .vue-slider-dot-handle {
    border-color: #69c0ff;
}

.vue-slider-dot-handle-focus {
    border-color: #36abff;
    box-shadow: 0 0 0 5px rgba(54, 171, 255, .2);
}

.vue-slider-dot-handle:hover,
.vue-slider:hover .vue-slider-dot-handle-focus,
.vue-slider:hover .vue-slider-dot-handle:hover {
    border-color: #36abff;
}

.vue-slider-dot-handle-disabled {
    cursor: not-allowed;
    border-color: #ddd !important;
}

.vue-slider-dot-tooltip {
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
}

.vue-slider-dot-tooltip-inner {
    font-size: 14px;
    white-space: nowrap;
    padding: 6px 8px;
    color: #fff;
    border-radius: 5px;
    border-color: rgba(0, 0, 0, .75);
    background-color: rgba(0, 0, 0, .75);
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
    transform: scale(.9);
    transition: transform .3s;
}

.vue-slider-dot-tooltip-inner:after {
    content: "";
    position: absolute;
}

.vue-slider-dot-tooltip-inner-top:after {
    top: 100%;
    left: 50%;
    transform: translate(-50%);
    height: 0;
    width: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px;
    border-top-color: inherit;
}

.vue-slider-dot-tooltip-inner-bottom:after {
    bottom: 100%;
    left: 50%;
    transform: translate(-50%);
    height: 0;
    width: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px;
    border-bottom-color: inherit;
}

.vue-slider-dot-tooltip-inner-left:after {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 0;
    width: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px;
    border-left-color: inherit;
}

.vue-slider-dot-tooltip-inner-right:after {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 0;
    width: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px;
    border-right-color: inherit;
}

.vue-slider-dot-tooltip-inner-top {
    transform-origin: 50% 100%;
}

.vue-slider-dot-tooltip-inner-bottom {
    transform-origin: 50% 0;
}

.vue-slider-dot-tooltip-inner-left {
    transform-origin: 100% 50%;
}

.vue-slider-dot-tooltip-inner-right {
    transform-origin: 0 50%;
}

.vue-slider-dot-tooltip-show,
.vue-slider-dot:hover .vue-slider-dot-tooltip {
    opacity: 1;
    visibility: visible;
}

.vue-slider-dot-tooltip-show .vue-slider-dot-tooltip-inner,
.vue-slider-dot:hover .vue-slider-dot-tooltip .vue-slider-dot-tooltip-inner {
    transform: scale(1);
}

/* ********************************************************************************************************************************************************************************************************************************************************************************************** */
.glowing-input[data-v-058d84c4] {
    -webkit-animation: glow-data-v-058d84c4 3s ease-in-out infinite;
    animation: glow-data-v-058d84c4 3s ease-in-out infinite;
}

.glowing-input[data-v-058d84c4]:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

@-webkit-keyframes glow-data-v-058d84c4 {
    0% {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }

    50% {
        box-shadow: 0 0 24px rgba(12, 255, 85, .733);
        border-color: #1bc870;
    }

    to {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }
}

@keyframes glow-data-v-058d84c4 {
    0% {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }

    50% {
        box-shadow: 0 0 24px rgba(12, 255, 85, .733);
        border-color: #1bc870;
    }

    to {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }
}

/* ********************************************************************************************************************************************************************************************************************************************************************************************** */
.glowing-input[data-v-744098e4] {
    -webkit-animation: glow-data-v-744098e4 3s ease-in-out infinite;
    animation: glow-data-v-744098e4 3s ease-in-out infinite;
}

.glowing-input[data-v-744098e4]:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

@-webkit-keyframes glow-data-v-744098e4 {
    0% {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }

    50% {
        box-shadow: 0 0 24px rgba(12, 255, 85, .733);
        border-color: #1bc870;
    }

    to {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }
}

@keyframes glow-data-v-744098e4 {
    0% {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }

    50% {
        box-shadow: 0 0 24px rgba(12, 255, 85, .733);
        border-color: #1bc870;
    }

    to {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }
}

/* ********************************************************************************************************************************************************************************************************************************************************************************************** */
.glowing-input[data-v-423df7e9] {
    -webkit-animation: glow-data-v-423df7e9 3s ease-in-out infinite;
    animation: glow-data-v-423df7e9 3s ease-in-out infinite;
}

.glowing-input[data-v-423df7e9]:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

@-webkit-keyframes glow-data-v-423df7e9 {
    0% {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }

    50% {
        box-shadow: 0 0 24px rgba(12, 255, 85, .733);
        border-color: #1bc870;
    }

    to {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }
}

@keyframes glow-data-v-423df7e9 {
    0% {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }

    50% {
        box-shadow: 0 0 24px rgba(12, 255, 85, .733);
        border-color: #1bc870;
    }

    to {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }
}

/* ********************************************************************************************************************************************************************************************************************************************************************************************** */
.glowing-input[data-v-34657c9b] {
    -webkit-animation: glow-data-v-34657c9b 3s ease-in-out infinite;
    animation: glow-data-v-34657c9b 3s ease-in-out infinite;
}

.glowing-input[data-v-34657c9b]:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

@-webkit-keyframes glow-data-v-34657c9b {
    0% {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }

    50% {
        box-shadow: 0 0 24px rgba(12, 255, 85, .733);
        border-color: #1bc870;
    }

    to {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }
}

@keyframes glow-data-v-34657c9b {
    0% {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }

    50% {
        box-shadow: 0 0 24px rgba(12, 255, 85, .733);
        border-color: #1bc870;
    }

    to {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }
}

/* ********************************************************************************************************************************************************************************************************************************************************************************************** */
@-webkit-keyframes glow-data-v-03c7afd2 {
    0% {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }

    50% {
        box-shadow: 0 0 24px rgba(12, 255, 85, .733);
        border-color: #1bc870;
    }

    to {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }
}

@keyframes glow-data-v-03c7afd2 {
    0% {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }

    50% {
        box-shadow: 0 0 24px rgba(12, 255, 85, .733);
        border-color: #1bc870;
    }

    to {
        box-shadow: 0 0 1px rgba(19, 253, 89, .28);
        border-color: #313d50;
    }
}

/* ********************************************************************************************************************************************************************************************************************************************************************************************** */


.dark:text-secondary-light {
    color: rgba(211, 242, 218, 1);
}