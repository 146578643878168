.search-part {
  border-radius: 20px;
  background-color: #23323c;
  margin-top: 10px;
}

.light .search-part {
  background-color: #e5eaee;
}

.light .search-part input {
  color: #14141f !important;
}

.token-search-bar {
  width: 100%;
}

.token-search-bar input {
  color: #bfdbc7;
  font-size: 14px;
  width: 100% !important;
}

.token-search-bar:active {
  box-shadow: none
}

.token-search-bar:focus {
  box-shadow: none
}

.token-display {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 20;
  margin-top: 5px;
  border: 1px solid gray;
  padding: 1;
  background-color: #142028;
  max-height: 400px;
  overflow-y: overlay;
}

.token-search-input {
  width: 100%;
  color: #f1df37;
  max-width: 360;
}

.network-select {
  background-color: #23323c;
  border-radius: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  color: white;
  width: 100%;
  height: 45px;
}

.arrow {
  background: transparent;
  height: 15px;
}

.forarrow {
  background: linear-gradient(90deg, rgb(126, 101, 101), rgb(126, 111, 111));
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
  margin-left: 23px;
}

.alarm-main {
  position: absolute;
  top: 7px;
  left: -100px;
  right: -100px;
  z-index: 10;
  border-radius: 5px;
  box-shadow: 0px 2px 7px 1px grey;
  overflow: hidden;
}

.alarm-bg {
  background: linear-gradient(90deg, rgb(126, 101, 101), rgb(126, 111, 111));
}

.alarm-color {
  color: white;
}

.alarm-loop {
  border-bottom: 1px black solid;
}

.alarm-header {
  padding: 10px;
  border-bottom: 1px black solid;
  height: 50px;
}

.alarm-body {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 300px;
}

.alarm-title {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.alarm-img {
  padding: 5px;
}

.alarm-letter {
  padding: 5px;
  border: 1px grey saddlebrown;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alarm-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-bar {
  background: linear-gradient(90deg, #dc1a22, #dc1a22, #fff);
  height: 43px;
  width: 100%;
}

.header-list {
  padding: 0px 15px;
  font-size: 18px;
  color: white;
  text-decoration: none;
}

.bell {
  width: 20px;
  height: 25px;
  cursor: pointer;
}

.logo {
  width: 100px;
  height: 100px;
}

.level-display {
  border-radius: 50%;
  padding: 3px 5px;
  background-color: #f59c00;
}

.toggle-effect {
  background-color: #cd1922 !important;
  color: white !important;
}

.toggle {
  background-color: transparent;
  border-radius: 50%;
  padding: 4px 7px;
}

.toggle-button {
  border: none;
  outline: none;
  background: rgb(31, 29, 29);
}

.toggle-icon {
  color: #7c777a;
}

.footer-social {
  border-radius: 50%;
  padding: 2px 6px;
  background: black;
  margin: 5px 5px;
}

.footer .a {
  padding: 3px;
  text-align: left;
  border-bottom: 1px rgb(100, 19, 5) solid;
}

.footer .a a {
  color: black;
}

.footer .a a:hover {
  color: #c42e08;
}

.dis-d-1000 {
  display: none;
}

@media (max-width: 1000px) {
  .dis-n-1000 {
    display: none;
  }

  .dis-d-1000 {
    display: flex;
  }

  .mobile-button,
  .tf-item-details.style-2 .content-right .meta-item-details,
  .tf-item-details.style-2 .content-right .meta-item {
    display: block !important;
  }
}

@media (max-width: 770px) {
  .wallet-letter {
    display: none;
  }

  .wallet-btn {
    border-radius: 50%;
  }
}

.dis-527 {
  display: none;
}

@media (max-width: 527px) {
  .dis-527 {
    display: inline;
  }

  .dis-527 img {
    height: 41px !important;
  }

  .dis-n-527 {
    display: none;
  }


}

@media (max-width: 370px) {
  .header .header-top {
    padding-bottom: 30px;
  }

  .header .header-top .ml2 {
    margin-left: 10px;
  }

  .header .header-bar .dis-527 img {
    width: 98%;
  }
}

/* Mobile Menu Button */
/* **************************************************************************************** */
/* @media (max-width:1001px) {
    .mobile-button{
        display: none;
    }
} */
.mobile-button {
  display: none;
  width: 26px;
  height: 26px;
  float: right;
  background-color: transparent;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mobile-button:before,
.mobile-button:after,
.mobile-button span {
  background-color: #070707;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.mobile-button:before,
.mobile-button:after {
  content: "";
  position: absolute;
  top: 0;
  height: 3px;
  width: 100%;
  left: 0;
  top: 50%;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.mobile-button span {
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  top: 50%;
  overflow: hidden;
  text-indent: 200%;
}

.mobile-button:before {
  -webkit-transform: translate3d(0, -7px, 0);
  -moz-transform: translate3d(0, -7px, 0);
  transform: translate3d(0, -7px, 0);
}

.mobile-button:after {
  -webkit-transform: translate3d(0, 7px, 0);
  -moz-transform: translate3d(0, 7px, 0);
  transform: translate3d(0, 7px, 0);
}

.mobile-button.active span {
  opacity: 0;
}

.mobile-button.active:before {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  -moz-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
}

.mobile-button.active:after {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  -moz-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
}

/* Menu
-------------------------------------------------------------- */
/* Main Nav */
.bg-for-hambuger-btn {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(31, 29, 29, 0.4);
}

.main-nav ul {
  margin: 0px;
}

.main-nav ul.menu {
  display: flex;
  padding: 0px !important;
}

.main-nav ul li {
  position: relative;
  list-style: none !important;
  list-style-type: none !important;
}

.main-nav>ul>li {
  padding: 10px 6px 10px 6px;
}

.main-nav>ul>li>a {
  position: relative;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #261b2a;
  padding-right: 10.5px;
}

.main-nav>.menu>li.menu-item-has-children>a::after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Sub Menu */

.main-nav .sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 250px;
  background-color: #ffff;
  border-radius: 6px;
  box-shadow: -2px 6px 23px #b4b4b44f;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
  transform: translateY(15px);
}

.main-nav .sub-menu::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 25px;
  height: 15px;
  width: 15px;
  transform: rotate(45deg);
  background-color: #fff;
}

.main-nav .right-sub-menu {
  left: auto;
  right: 0;
}

.main-nav .sub-menu li a {
  display: block;
  font-size: 18px;
  color: #14141f;
  padding: 20px;
  font-weight: 700;
}

.main-nav .sub-menu li:not(:last-child) a {
  border-bottom: 1px solid #e1e1e18e;
}

.main-nav li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.main-nav .sub-menu li.current-item a,
.main-nav .sub-menu li a:hover {
  color: var(--primary-color3);
}

.main-nav>ul>li>a:hover {
  color: #f59c00 !important;
}

.main-nav>ul>li .sub-menu li {
  position: relative;
}

.main-nav>ul>li .sub-menu li a {
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
}

.main-nav>ul>li .sub-menu li a:hover,
.main-nav>ul>li .sub-menu li.current-item a {
  padding-left: 35px;
}

.main-nav>ul>li .sub-menu li a:hover::after,
.main-nav>ul>li .sub-menu li.current-item a:after {
  width: 12px;
}

.main-nav>ul>li .sub-menu li a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background: var(--primary-color3);
  left: 15px;
  top: 28px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media only screen and (max-width: 1600px) {
  .main-nav {
    left: 18%;
  }

  .main-nav>ul>li {
    padding: 10px 6px 10px 6px;
  }
}

/* Mobile Menu */
@media only screen and (max-width: 991px) {
  .main-nav {
    margin: 0 auto;
    width: 40%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background-color: #fff;
    box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
    transform: translateX(-100%);
    transition: transform 0.5s ease;
  }

  .is_dark .main-nav {
    background: #14141f;
  }

  .main-nav ul.menu {
    flex-direction: column;
  }

  .main-nav>ul>li:first-child {
    border: none;
  }

  .main-nav>ul>li {
    padding: 0;
    border-top: 1px solid rgba(138, 138, 160, 0.3);
  }

  .main-nav ul>li>a {
    line-height: 48px;
    padding: 0 15px;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
  }

  .main-nav.active {
    transform: translateX(0);
  }

  .main-nav.active ul {
    flex-direction: column;
  }

  .main-nav.active ul li {
    margin: 0;
    text-align: left;
    cursor: pointer;
    padding: 0;
    border-top: 1px solid rgba(138, 138, 160, 0.3);
    background-color: #fff;
  }

  .is_dark .main-nav.active ul li {
    background-color: #14141f;
  }

  .main-nav.active ul>li>a {
    color: #14141f;
    display: inline-block;
    font-size: 16px;
    line-height: 48px;
    padding: 0 15px;
    font-weight: 500;
  }

  .is_dark .main-nav.active ul>li>a {
    color: #fff;
  }

  .main-nav.active ul li:first-child {
    border-top: 0px;
  }

  .main-nav.active ul li>ul>li:first-child {
    border-top: 1px solid rgba(138, 138, 160, 0.3);
  }

  .main-nav.active ul>li.current-menu-item>a {
    color: var(--primary-color3);
  }

  .is_dark .main-nav.active ul>li.current-menu-item>a {
    background: linear-gradient(216.56deg, #e250e5 5.32%, #4b50e6 94.32%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .main-nav.active .current-item>a,
  .main-nav.active ul>li>a:hover {
    color: var(--primary-color3);
  }

  .is_dark .main-nav.active .current-item>a,
  .is_dark .main-nav.active ul>li>a:hover {
    background: linear-gradient(216.56deg, #e250e5 5.32%, #4b50e6 94.32%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .main-nav.active .sub-menu .menu-item a {
    margin-left: 15px;
  }

  .main-nav.active .menu-item-has-children .arrow {
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    font-family: "FontAwesome";
    line-height: 48px;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 0;
    width: 48px;
  }

  .main-nav.active .menu-item-has-children .arrow:before {
    font-family: "Font Awesome 5 Pro";
    content: "\f107";
    color: var(--primary-color2);
  }

  .main-nav.active .menu-item-has-children.current-menu-item .arrow:before {
    color: var(--primary-color3);
  }

  .main-nav.active .menu-item-has-children.current-menu-item .arrow:before {
    background: linear-gradient(216.56deg, #e250e5 5.32%, #4b50e6 94.32%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .main-nav.active .menu-item-has-children .arrow.active {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .main-nav.active .menu-item-has-children .arrow.active:before {
    content: "\f107";
  }

  .main-nav.active ul ul li {
    background-color: var(--primary-color);
    border-top: 1px solid rgba(138, 138, 160, 0.3);
  }

  .is_dark .main-nav.active ul ul li {
    background-color: #14141f;
  }

  .main-nav .sub-menu {
    width: 100%;
  }

  .is_dark .main-nav .sub-menu {
    background: #14141f;
  }

  .main-nav .sub-menu::before {
    display: none;
  }

  .main-nav.active .sub-menu {
    position: relative;
    display: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
  }

  .main-nav.active .menu-item.active .sub-menu {
    display: block;
  }

  .bg-for-hambuger-btn.active {
    display: block;
  }

  .main-nav.active .sub-menu::before {
    display: none;
  }

  .main-nav.active .sub-menu li:not(:last-child) a {
    border: none;
  }

  .header_1.header_2 #main-nav {
    left: 0;
  }
}

.main-nav a {
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .main-nav {
    width: 65%;
  }
}

@media only screen and (max-width: 640px) {
  .main-nav {
    width: 65%;
  }

  .copyright {
    display: flex;
    justify-content: center;
    top: 0 !important;
    position: relative !important;
  }
}

@media only screen and (max-width: 640px) {
  .alarm-main {
    left: -180px;
    right: -50px;
  }
}

@media only screen and (max-width: 370px) {
  .forarrow {
    margin-left: 13px;
  }
}

@media only screen and (max-width: 380px) {
  .hidden-logo {
    display: none;
  }
}


@media only screen and (max-width: 500px) {
  .token-display {
    position: fixed;
  }
}